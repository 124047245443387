import { Controller } from "@hotwired/stimulus"

//
//== FANCYBOX
import { Fancybox } from "@fancyapps/ui";



export default class extends Controller {

  connect () {

    let check_download= document.querySelector('[data-download="true"]')

    let tool_right= ["zoomIn", "zoomOut", "thumbs", "close"];

    if (check_download) {
      tool_right= ["zoomIn", "zoomOut", "download", "thumbs", "close"];
    }


    Fancybox.bind("[data-fancybox]", {
      Toolbar: {
        display: {
          left: ["infobar"],
          middle: [],
          right: tool_right,
        }
      }
    })
  }

  disconnect () {
    Fancybox.destroy();
  }
}
